import { render, staticRenderFns } from "./Userowner.vue?vue&type=template&id=1e44668d&scoped=true&"
import script from "./Userowner.vue?vue&type=script&lang=js&"
export * from "./Userowner.vue?vue&type=script&lang=js&"
import style0 from "./Userowner.vue?vue&type=style&index=0&id=1e44668d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e44668d",
  null
  
)

export default component.exports