<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar title="二手车质保签约" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div style="height: 100%;" class="tophes">
      <img class="qyimg" src="../../assets/img/qyimg.png" alt />
      <div class="h1">签约流程</div>
      <div class="hec">
        <span class="left shu">1</span>
        <span class="left">销售商提交车辆资料</span>
        <span class="right yitij">
          <span v-if="list.id">已提交</span>
          <span v-else>未提交</span>
        </span>
      </div>
      <div class="hec">
        <span class="left shu">2</span>
        <span class="left">客户提交车辆检测申请</span>
        <span class="right ma2" @click="toerweima" v-if="list.status >= 1">
          <vue-qr
            :text="'http://customer.car-posthouse.cn/second_hand?id='+list.id"
            :margin="0"
            colorDark="#000"
            colorLight="#fff"
            :dotScale="1"
            :logoScale="0.2"
            :size="130"
          ></vue-qr>
        </span>
        <span class="right" v-else>待客户扫码注册</span>
      </div>
      <div class="hec">
        <span class="left shu">3</span>
        <span class="left">质保公司进行车辆检测</span>
        <span class="right" v-if="list.status <= 1" style="color: red;">待检测</span>
        <span class="right" v-else style="color: #666;" @click="tojiance">已检测</span>
      </div>
      <div class="hec">
        <span class="left shu">4</span>
        <span class="left">甲乙丙三方签约</span>
        <span class="left" style=" margin-left: 20px;">
          <img class="gimg" v-if="list.checkSign == null" src="../../assets/img/cha.png" alt />
          <img class="gimg" v-else src="../../assets/img/gou.png" alt />
          <span style="vertical-align: middle;">质保方</span>
        </span>
        <span class="left" style=" margin-left: 10px;">
          <img class="gimg" v-if="list.sellSign == null" src="../../assets/img/cha.png" alt />
          <img class="gimg" v-else src="../../assets/img/gou.png" alt />
          <span style="vertical-align: middle;">销售方</span>
        </span>
        <span class="left"></span>
        <span class="right yitij" @click="toqianyue" v-if="list.customerSign==null">去签约</span>
        <span class="right" @click="toqianyue" v-else style="color: #07c160;">已签约</span>
      </div>
      <div class="hec">
        <span class="left shu">5</span>
        <span class="left">完成</span>
      </div>
      <div style="height: 10px;"></div>
      <van-popup class="tanchuan" v-model="show">
        <vue-qr
          :text="qrCode"
          :margin="0"
          colorDark="#000"
          colorLight="#fff"
          :dotScale="1"
          :logoScale="0.2"
          :size="240"
        ></vue-qr>
      </van-popup>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import { detailsuid } from "../../api/check.js";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
import vueQr from "vue-qr";
export default {
  components: { vueQr },
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      actv: 1,
      list: [],
      search: "",
      qrCode: "",
      show: false,
      size: 10,

      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    toerweima() {
      this.qrCode =
        "http://customer.car-posthouse.cn/second_hand?id=" + this.list.id;
      this.show = true;
    },
    onClickRight() {
      this.$router.push({
        path: "Add_car"
      });
    },
    //跳转检测
    tojiance() {
      this.$router.push({
        path: "examining_report",
        query: {
          id: this.list.id,
          checkDate: this.list.checkDate,
          carBrand: this.list.carBrand,
          standard: this.list.standard,
          vin: this.list.vin,
          carNo: this.list.carNo,
          checkSign: this.list.checkSign
        }
      });
    },
    toqianyue() {
      if (this.list.checkStatus == 0) {
        this.$toast("请质检方检测完再签名！");
        return;
      }
      this.$router.push({
        path: "contract",
        query: {
          id: this.list.id
        }
      });
    },
    tab(e) {
      this.actv = e;
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        uid: this.userInfo ? this.userInfo.id : ""
        // uid: 1661
      };
      detailsuid(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      // this.page++;
      this.onLoad();
    },

    back() {
      this.$router.push({
        path: "user"
      });
      // this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.tophes {
  padding-top: 46px;
}

.qyimg {
  width: 100%;
}
.h1 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 45px;
}
.hec {
  border-top: 1px solid #f1f1f1;
  overflow: hidden;
  line-height: 55px;
  font-size: 13px;
  padding: 0 15px;
  vertical-align: bottom;
}
.shu {
  border: 1px solid #333;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  line-height: 14px;
  text-align: center;
  margin-top: 19px;
  margin-right: 5px;
}
.yitij {
  width: 55px;
  text-align: center;
  height: 24px;
  line-height: 22px;
  border: 1px solid #999;
  border-radius: 5px;
  color: #999;
  margin-top: 16px;
  font-size: 12px;
}
.ma2 {
  position: relative;
}
.ma2 img {
  width: 40px !important;
  height: 40px !important;
  display: block !important;
  margin-top: 7px;
  // margin-right: 8px;
  float: right;
}
.tanchuan {
  padding: 15px;
  // width: 70%;
}
.gimg {
  width: 20px;
  vertical-align: middle;
  margin-right: 3px;
}
</style>